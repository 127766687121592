console.log('top');

$(window).on('load', function(){
    setTimeout(function () {
		$('.lTxt').addClass('show');
        $('.sTxt').addClass('show');
	}, 0);
    setTimeout(function () {
		$('.merit').addClass('show');
	}, 1000);
    setTimeout(function () {
		$('.line').addClass('show');
	}, 2000);
});