var pageId = '';

pageId = document.querySelector('body').getAttribute('id');
switch (pageId) {
	//body id='top'
	case 'top':
		require('./top.ts');
	break;

	//body id='map'
	case 'outline':
    window.addEventListener('DOMContentLoaded', function(){
      const todayElm = document.querySelectorAll('.js-todayElm');
      const nextElm = document.querySelectorAll('.js-nextElm');
      const upToday = new Date();
      const upNext = new Date();
      upNext.setDate(upToday.getDate() + 14)

      const todayM = upToday.getMonth()+1;
      const nextM = upNext.getMonth()+1;
      for (let i = 0; i < todayElm.length; i++) {
        todayElm[i].innerHTML = upToday.getFullYear()+"年"+todayM+"月"+upToday.getDate()+"日";
      }
      for (let i = 0; i < nextElm.length; i++) {
        nextElm[i].innerHTML = upNext.getFullYear()+"年"+nextM+"月"+upNext.getDate()+"日";
      }
    });
	break;
};

// ページ遷移時にフェードイン
window.addEventListener('load', function(){
  document.querySelector('body').classList.add('show');
});

const pageChTrigger = document.querySelectorAll('a:not([href^="#"]):not([target]):not([href^="tel:"])');
for (let i = 0; i < pageChTrigger.length; i++){
  pageChTrigger[i].addEventListener('click', (event) => {
  event.preventDefault();
  const url = pageChTrigger[i].getAttribute('href');
  console.log(url);
  if (url !== '') {
    document.querySelector('body').classList.remove('show');
    setTimeout(() => {
      window.location = url;
    }, "1000")
  }
  return false;
  });
};

window.addEventListener("pageshow", function(event){
  if (event.persisted) {
    document.querySelector('body').classList.add('show');
  }
});

//common
require('./anime.ts');

const header = document.querySelector('header');
window.addEventListener('scroll',function(){
  var sH = window.scrollY;
  if(sH >= 100){
    header.classList.add('hScroll');
  }else{
    header.classList.remove('hScroll');
  }
});

const gBtn = document.querySelector('.g_menu_btn');
const gNav = document.querySelector('.g_nav');
gBtn.addEventListener('click',function(){
  this.classList.toggle('active');
  gNav.classList.toggle('active');
});

const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){
  smoothScrollTrigger[i].addEventListener('click', (event) => {
    event.preventDefault();
    let href = smoothScrollTrigger[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.scrollY;
    const gap = 0;
    const target = rect + offset - gap;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
};

// $(function(){
//   $('.tubbtn_01').on('click', function(){
//     $(this).addClass('active');
//     $('.access_title').addClass('active');
//     $('.tubbtn_02').removeClass('active');
//     $('.location_title').removeClass('active');
//     $('.access_wrap').addClass('active');
//     $('.location_wrap').removeClass('active');
//   });
// });

// $(function(){
//   $('.tubbtn_02').on('click', function(){
//     $(this).addClass('active');
//     $('.location_title').addClass('active');
//     $('.tubbtn_01').removeClass('active');
//     $('.access_title').removeClass('active');
//     $('.access_wrap').removeClass('active');
//     $('.location_wrap').addClass('active');
//   });
// });

// $(function(){
//   $('.tubbtn_shopping').on('click', function(){
//     $(this).addClass('active');
//     $('.tubbtn').removeClass('active');
//     $('.tub_cntnt').removeClass('active');
//     $('.tub_shopping').addClass('active');
//   });
// });

// $(function(){
//   $('.tubbtn_park').on('click', function(){
//     $(this).addClass('active');
//     $('.tubbtn').removeClass('active');
//     $('.tub_cntnt').removeClass('active');
//     $('.tub_park').addClass('active');
//   });
// });

$(function() {
  $('.tubbtn_box_02 .tubbtn').on('click', function() {
    let index = $('.tubbtn_box_02 .tubbtn').index(this);

    $('.tubbtn_box_02 .tubbtn').removeClass('active');
    $('.tub_cntnt').removeClass('active');
        $('.map').removeClass('active');

    $('.tubbtn_box_02 .tubbtn').eq(index).addClass('active');
    $('.tub_cntnt').eq(index).addClass('active');
        $('.map').eq(index).addClass('active');

  });
});

$(function(){
  $('.slider_shopping').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // fade: true,
    asNavFor: '.thumbnail_shopping',
  });
  $('.thumbnail_shopping').slick({
    slidesToShow: 1,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '25%',
    asNavFor: '.slider_shopping',
  });
  $('.slider_park').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // fade: true,
    asNavFor: '.thumbnail_park',
  });
  $('.thumbnail_park').slick({
    slidesToShow: 1,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '25%',
    asNavFor: '.slider_park',
  });
  $('.slider_education').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // fade: true,
    asNavFor: '.thumbnail_education',
  });
  $('.thumbnail_education').slick({
    slidesToShow: 1,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '25%',
    asNavFor: '.slider_education',
  });
  $('.slider_medical').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // fade: true,
    asNavFor: '.thumbnail_medical',
  });
  $('.thumbnail_medical').slick({
    slidesToShow: 1,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '25%',
    asNavFor: '.slider_medical',
  });
  $('.slider_others').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // fade: true,
    asNavFor: '.thumbnail_others',
  });
  $('.thumbnail_others').slick({
    slidesToShow: 1,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '25%',
    asNavFor: '.slider_others',
  });
});